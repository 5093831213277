import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import DateConverter from "../../shared/dateConverter/dateConverter";
import CustomDataTable from "../../shared/customDataTable/customDataTable";
import CreateButton from "../../shared/customButtons/createButton/createButton";
import { vehicleApi, vehicleDeleteApi, vehicleSearchApi } from "../../utill/api/vehicleApi";
import VehicleAddModal from "./VehicleAddModal";
import ToastAlert from "../../shared/notificationAlert/toastAlert";
import { permissionModules,OBU_MAKE_TYPE } from "../../utill/config";
import ServerSearch from "../../shared/server-search/server-serach";
import VehicleActions from "./VehicleActions";
import ConfirmDeleteModal from "../../shared/modals/confirmDeleteModal";


/**
 *
 */
class Vehicles extends Component {

columns = [
  {
    name: "#",
    sortable: true,
    minWidth: "60px",
    maxWidth: "50px",
    cell: row => row.index
  },
  {
    name: "Serial Number",
    selector: "serialNumber",
    sortable: true,
    minWidth: "200px",
    cell: row => <span>{row.serialNumber}</span>
  },
  {
    name: "VPart Number",
    selector: "vPartNumber",
    sortable: true,
    minWidth: "200px",
    cell: row => <span>{row.vPartNumber}</span>
  },
  {
    name: "VIN",
    selector: "vin",
    sortable: true,
    minWidth: "200px",
    cell: row => <span>{row.vin}</span>
  },
  {
    name: "TSP",
    selector: "tsp",
    sortable: false,
    minWidth: "200px",
    cell: row => <span>{row.tsp}</span>
  },
  {
    name: "OBU Make",
    selector: "obuMakeId",
    sortable: false,
    minWidth: "250px",
    cell: row => <span>{OBU_MAKE_TYPE[row.obuMakeId]}</span>
    
  }, 
  {
    name: "Remote Immobilize",
    selector: "remoteImmobilize",
    sortable: false,
    minWidth: "250px",
    cell: row => <span>{row.remoteImmobilize}</span>
    
  }, 
  {
    name: "Created By",
    selector: "createdBy",
    sortable: false,
    minWidth: "200px",
    cell: row => <span>{row.createdBy}</span>
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    minWidth: "200px",
    cell: row => <DateConverter date={row.createdAt} />
  },{
    name: "Updated By",
    selector: "updatedBy",
    sortable: false,
    minWidth: "200px",
    cell: row => <span>{row.updatedBy}</span>
  },
  {
    name: "Updated At",
    selector: "updatedAt",
    sortable: true,
    minWidth: "200px",
    cell: row => <DateConverter date={row.updatedAt} />
  }
  ,
  {
    name: "Action",
    selector: "action",
    sortable: false,
    minWidth: "200px",
    cell: row => (
      <>
        <VehicleActions
          row={row}
          confirmDelete={this.confirmDelete}
    
        />
      </>
    )
  }
];
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  currentPage = 0;
  searchDropdownlist = [
    { displayText: "Serial Number", key: "serialNumber" },
    { displayText: "VIN", key: "vin" }
  ];

  constructor() {
    super();
    this.state = {
      vehiclesData: [],
      error: null,
      isLoading: true,
      showDeleteModal: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getVehicles = this.getVehicles.bind(this);
  }
  componentDidMount() {
    /**
     * This is not called here because the useEffect Hook in the customDataTable 
     * component calls it automatically in the initial rendering.
     * This methodology is followed across all class components using customDataTable.
     */
    // this.getVehicles();
    if (this.currentUser == null || this.currentUser === undefined) {
      localStorage.clear();
      this.props.history.push('/login');
    }
  }
  /**
   * To get files
   */
  getVehicles(page) {
    vehicleApi(page)
      .then(res => {
        if (res.status !== 200) {
          res.json().then(res => {
            this.setState({ error: res, isLoading: false });
          });
        } else {
          res.json().then(res => {
            if (res.length != 0) {
              this.setIndexNumber(res,page);
            } else this.setState({ isLoading: false });
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ isLoading: false, error: e });
      });
  }
  /**
   * To set index for each row
   */
  setIndexNumber = (data, page) => {
    if(page == 0) {
      this.setState({ vehiclesData: [] });
    }
    data.map((row, index) => {
      return row.index = this.state.vehiclesData.length + index + 1;
    });
    if(page == 0) {
      this.setState({
        vehiclesData: data,
        isLoading: false
      })
    } else {
      this.setState(prevState => ({
        vehiclesData: [...prevState.vehiclesData, ...data],
        isLoading: false
      }));
    }
  };
  /**
   *
   */
  toggleModal() {
    this.setState(oldState => ({
      showModal: !oldState.showModal
    }));
  };

  errorHandled = () => {
    this.setState({ error: null });
  };

  showModalSuccess = (e) => {
    this.setState({ error: e });
  };

  loadNextPage = (page, currentPage) => {
    this.currentPage = currentPage;
    this.getVehicles(page);
  };

  handleSearch = async (key, value) => {
    if (value.length && (key === 'serialNumber' || (key === 'vin' && value.length >= 5))) {
      this.setState({ isLoading: true });
      const apiPromice = await vehicleSearchApi(key, value);
      const res = await apiPromice.json();
      if (res.error && res.status !== 200) {
        this.setState({ error: res });
      } else {
        this.setIndexNumber(res, 0);
      }
    } else if (!value.length) this.getVehicles(0);
  };

  confirmDelete = data => {
    if (!this.state.showDeleteModal) {
      this.setState({ showDeleteModal: true, deleteData: data });
    } else if (this.state.showDeleteModal) {
      this.setState({ showDeleteModal: false });
    }
  }

  deleteVehicle = () => {
    this.setState({ disableConfirmButton: true });
    vehicleDeleteApi(this.state.deleteData.vehicleId)
      .then(res => {
        if (res.status === 200) {
          res.successCode = "deleteVehicleSuccess"
          this.setState({
            error: res,
            deleteData: {},
            showDeleteModal: false,
            disableConfirmButton: false
          },() => this.getVehicles(0));
        } else if (res.status !== 200) {
          res.json().then(res => {
            this.setState({
              error: res,
              showDeleteModal: false,
              disableConfirmButton: false
            });
          });
        }
      })
      .catch(e => {
        this.setState({
          error: e,
          showDeleteModal: false,
          disableConfirmButton: false
        });
      });
  }

  render() {
    return (
      <>
        {/* Toast Alert */}
        {Boolean(this.state.error) ? (
          <ToastAlert
            show={Boolean(this.state.error)}
            onClose={this.errorHandled}
            error={this.state.error}
          />
        ) : null}
        {/* Page Content starts */}
        <Container fluid>
          <Row className="page-title">
            <Col className="col-3">
              <h1>Vehicles</h1>
            </Col>
            <Col className="col-5">
              {/* Search Dropdown */}
              <ServerSearch
                dropdown
                list={this.searchDropdownlist}
                search={this.handleSearch}
              />
            </Col>
            <Col className="col-4 text-right">
              {this.currentUser &&
              this.currentUser.userType &&
              permissionModules[this.currentUser.userType].admin.includes("create") ? (
                <CreateButton
                  displaytext="New Vehicle"
                  onClick={() => this.setState({ showModal: true })}
                />
              ) : null}
            </Col>
          </Row>
          <Row noGutters>
            <Col className="data-table">
              <CustomDataTable
                tableColumns={this.columns}
                data={this.state.vehiclesData}
                loading={this.state.isLoading}
                loadNextPage={this.loadNextPage}
                currentPage={this.currentPage}
              />
            </Col>
          </Row>
        </Container>
        {this.state.showModal ? (
          <VehicleAddModal
            show={this.state.showModal}
            getVehicles={this.getVehicles}
            onHide={this.toggleModal}
            showModalSuccess={this.showModalSuccess}
          />
        ) : null}
         <ConfirmDeleteModal
          show={this.state.showDeleteModal}
          disable={this.state.disableConfirmButton}
          onHide={this.confirmDelete}
          update={this.deleteVehicle}
          action={"vehicleDelete"}
        />
      </>
    );
  }
}

export default Vehicles;
